@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/duotone-dark.css";
@import "~codemirror/theme/duotone-light.css";
@import "~codemirror/addon/tern/tern.css";
@import "~codemirror/addon/lint/lint.css";
@import "~codemirror/addon/dialog/dialog.css";
@import "~codemirror/addon/fold/foldgutter.css";

.CodeMirror-hyperlink {
  text-decoration: underline;
  cursor: pointer;
}

.codeium-ghost {
  font-style: italic;
  color: #888888;
}

.hide-text {
  display: none;
}
