.json-container {
  font-family: var(--font-monospace);
  font-size: 11px;
  font-weight: 400;
  color: #a4aab7; /* GREY_300 */
  box-sizing: border-box;
  border-radius: 4px;
  -ms-overflow-style: none; /* IE and Edge */

  --scrollbar-thumb-color: #c1c1c1;
  --scrollbar-track-color: transparent;
}

@supports (scrollbar-width: auto) {
  .json-container {
    scrollbar-width: none;
    scrollbar-color: var(--scrollbar-thumb-color) transparent;
  }

  .json-container:hover {
    scrollbar-width: thin;
  }

  .json-container.scrollbar-visible {
    scrollbar-width: thin;
  }

  .json-container.scrollbar-hidden:hover {
    scrollbar-width: none;
  }
}

/* Older browsers, its just a best attempt */
/* PostCSS doesnt like wrapping this in @supports selector(::-webkit-scrollbar) for some reason */
.json-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 6px;
  display: none;
}
.json-container::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}
.json-container::-webkit-scrollbar {
  max-width: 8px;
  max-height: 8px;
}
.json-container:hover::-webkit-scrollbar-thumb {
  display: initial;
}
.json-container.scrollbar-visible::-webkit-scrollbar-thumb {
  display: initial;
}
.json-container.scrollbar-hidden:hover::-webkit-scrollbar-thumb {
  display: none;
}

.json-container .line {
  margin-top: 4px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
}

.json-container .is-expandable {
  cursor: pointer;
}

.json-container .line.is-expandable {
  align-items: center;
}

.json-container .caret-icon {
  width: 12px;
  text-align: center;
  padding-left: 0px;
  padding-right: 14px;
}

.json-container .empty-icon {
  width: 12px;
}

.json-container .json-type {
  margin-right: 4px;
  margin-left: 4px;
}

.json-container .json-key {
  color: #444;
  margin-right: 4px;
  margin-left: 4px;
}

.json-container .virtual-key {
  color: #555;
  margin-right: 4px;
  margin-left: 4px;
}

.json-container .json-index {
  margin-right: 4px;
  margin-left: 4px;
}

.json-container .json-value {
  margin-left: 4px;
  color: #45b5ff;
}

.json-container .json-value.json-null {
  margin-left: 4px;
  color: #dc322f;
}

.json-container .json-undefined {
  color: #808080;
}

.json-container .json-size {
  margin-right: 4px;
  margin-left: 4px;
}

.json-container .hide {
  display: none;
}

.json-container .fas {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
}

.json-container .fa-caret-down {
  border-width: 6px 5px 0 5px;
  border-color: #d9d9d9 transparent;
}

.json-container .fa-caret-right {
  border-width: 5px 0 5px 6px;
  border-color: transparent transparent transparent #d9d9d9;
}
